import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><inlineCode parentName="p">{`useOnEscapePress`}</inlineCode>{` is a simple utility Hook that calls a user-provided function when the `}<inlineCode parentName="p">{`Escape`}</inlineCode>{` key is pressed. The hook sets up `}<inlineCode parentName="p">{`keydown`}</inlineCode>{` event listener on `}<inlineCode parentName="p">{`window.document`}</inlineCode>{` and executes the user-provided function if these conditions are met:`}</p>
    <ol>
      <li parentName="ol">{`The Escape key was pressed`}</li>
      <li parentName="ol">{`The `}<inlineCode parentName="li">{`preventDefault`}</inlineCode>{` method has not yet been called on the event object.`}</li>
    </ol>
    <p>{`Furthermore, unlike the normal behavior for multiple event listeners existing on the same DOM Node, if multiple `}<inlineCode parentName="p">{`useOnEscapePress`}</inlineCode>{` hooks are active simultaneously, the callbacks will occur in reverse order. In other words, if a parent component and a child component both call `}<inlineCode parentName="p">{`useOnEscapePress`}</inlineCode>{`, when the user presses Escape, the child component's callback will execute, followed by the parent's callback. Each callback has the chance to call `}<inlineCode parentName="p">{`.preventDefault()`}</inlineCode>{` on the event to prevent further callbacks.`}</p>
    <h3>{`Dependencies`}</h3>
    <p>{`Similar to `}<inlineCode parentName="p">{`useCallback`}</inlineCode>{`, `}<inlineCode parentName="p">{`useOnEscapePress`}</inlineCode>{` takes a `}<inlineCode parentName="p">{`React.DependencyList`}</inlineCode>{` as its second argument. These are the dependencies used to memoize the callback. Failing to provide the correct dependency list can result in degraded performance. If this argument is omitted, we will assume that the callback is already memoized. In the example below, that memoization occurs in `}<inlineCode parentName="p">{`DemoComponent`}</inlineCode>{` with a call to `}<inlineCode parentName="p">{`React.useCallback`}</inlineCode>{`, so `}<inlineCode parentName="p">{`OverlayDemo`}</inlineCode>{` does not need to pass a dependency list.`}</p>
    <h3>{`Usage`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript",
        "metastring": "live noinline",
        "live": true,
        "noinline": true
      }}>{`const OverlayDemo = ({onEscape, children}) => {
  useOnEscapePress(onEscape)
  return <Box height="200px">{children}</Box>
}

function DemoComponent() {
  const [isOpen, setIsOpen] = React.useState(false)
  const toggleOverlay = React.useCallback(() => {
    setIsOpen(isOpen => !isOpen)
  }, [])
  const closeOverlay = React.useCallback(() => {
    setIsOpen(false)
  }, [])
  return (
    <>
      <Button onClick={toggleOverlay}>toggle</Button>
      {isOpen && (
        <OverlayDemo onEscape={closeOverlay}>
          <Button>Button One</Button>
          <Button>Button Two</Button>
        </OverlayDemo>
      )}
    </>
  )
}

render(<DemoComponent />)
`}</code></pre>
    <h4>{`useOnEscapePress`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`onEscape`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`(event: KeyboardEvent) => void`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Function to call when user presses the Escape key`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`callbackDependencies`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`React.DependencyList`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Array of dependencies for memoizing the given callback`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      